import { ReactNode, useState } from 'react';
import { useEffect } from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.li<{
	$darkMode: boolean;
	$selected: boolean;
}>(({ $darkMode, $selected, theme }) => ({
	fontFamily: "'Roboto Mono', monospace",
	fontSize: 16,
	padding: '5px 0',
	color: $darkMode
		? $selected
			? 'white'
			: theme.colors.grey
		: $selected
		? theme.colors.dark
		: theme.colors.grey,
	listStyle: 'none',

	'&:hover': {
		cursor: 'pointer',
		color: $selected ? undefined : $darkMode ? 'white' : theme.colors.dark,
	},
}));

const getRandomChar = () => {
	// const chars = ['&', '@', '.', '/', '+', '-', '§', ')', '0', '%', '°', '_'];
	const chars = [
		'a',
		'b',
		'c',
		'd',
		'e',
		'f',
		'g',
		'h',
		'i',
		'j',
		'k',
		'l',
		'm',
		'n',
		'o',
		'p',
		'q',
		'r',
		's',
		't',
		'u',
		'v',
		'w',
		'x',
		'y',
		'z',
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
	];
	const random = Math.floor(Math.random() * chars.length);

	return chars[random];
};

const getContent = (children: ReactNode, start: number) => {
	const text = children ? children.toString() : '';
	let content = '';

	for (let i = 0; i < text.length; i++) {
		content +=
			i >= start
				? i < start + 5
					? text[i] === ' '
						? ' '
						: getRandomChar()
					: ''
				: text[i];
	}

	return content;
};

const Li: FC<
	{
		url: string;
		darkMode: boolean;
		selected: boolean;
		onClick: () => void;
	} & React.HTMLAttributes<HTMLLinkElement>
> = ({ url, darkMode, selected, onClick, children }) => {
	const [content, setContent] = useState<string>(getContent(children, 0));
	const [iteration, setIteration] = useState<number>(0);

	useEffect(() => {
		if (iteration < 60) {
			setTimeout(() => {
				setContent(getContent(children, (iteration - 10) / 3));
				setIteration(iteration + 1);
			}, 30);
		}
	}, [children, iteration]);

	return (
		<Container
			$darkMode={darkMode}
			$selected={selected}
			onClick={() => onClick()}
		>
			<Link to={'/' + url}>{content}</Link>
		</Container>
	);
};

export default Li;
