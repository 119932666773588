import styled from 'styled-components';
import { ResponsiveType } from '../types';

const ProjectElement = styled.div<{
	$pageLoaded: boolean;
	$darkMode: boolean;
	$responsive: ResponsiveType;
	$menuOpened: boolean;
}>(({ $pageLoaded, $responsive, $menuOpened, theme }) => {
	const leftPosition = theme.menuWidth + theme.margins[$responsive];

	return {
		position: 'absolute',
		left: 0,
		top: 0,
		width: '100%',
		height: '100%',
		overflowX: 'hidden',
		transition: 'opacity .5s ease',

		'div.project': {
			position: 'absolute',
			display: 'flex',
			flexWrap: 'wrap',
			width: 'calc(100% - 280px)',
			maxWidth: 1000 + 50,
			left: leftPosition,
			top: 180,
			opacity: $responsive !== 'desktop' && $menuOpened ? 0.4 : 1,
			transition: 'all .2s ease',
			paddingRight: 50,
			paddingBottom: 50,

			// eslint-disable-next-line
			['img.Image, div.VideoMobile']: {
				width: '100%',
				maxWidth: 1000,
				display: $pageLoaded ? undefined : 'none',
				borderRadius: {
					desktop: 7,
					tablet: 5,
					mobile: 3,
				}[$responsive],
				marginRight: 20,
				marginBottom: 20,
			},
		},

		[`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
			'div.project': {
				width: `calc(100% - ${theme.margins.tablet}px)`,
				left: $menuOpened ? leftPosition : theme.margins.tablet / 2,
				top: 100,
				justifyContent: 'space-evenly',
				paddingRight: 0,

				// eslint-disable-next-line
				['img.Image, div.VideoMobile']: {
					marginRight: 10,
					marginLeft: 10,
					marginBottom: 10,
				},
			},
		},

		[`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
			'div.project': {
				width: `calc(100% - ${theme.margins.mobile}px)`,
				left: $menuOpened ? leftPosition : theme.margins.mobile / 2,

				// eslint-disable-next-line
				['img.Image, div.VideoMobile']: {
					marginRight: 0,
					marginLeft: 0,
					marginBottom: 10,
				},
			},
		},
	};
});

export default ProjectElement;
