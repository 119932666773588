import React, { useEffect } from 'react';
import { useState } from 'react';
import { MediaType, ResponsiveType } from '../types';
import Image from './Image';
import ProjectDescription from './ProjectDescription';
import ProjectElement from './ProjectElement';
import ProjectLoader from './ProjectLoader';
import VideoMobile from './VideoMobile';
import { useTheme } from 'styled-components';
import { ThemeType } from '../style/ThemeProvider';
import getProjects from '../getProjects';

const Project = React.forwardRef<
	HTMLDivElement,
	{
		tabSelected: number;
		responsive: ResponsiveType;
		menuOpened: boolean;
		onClick: () => void;
	}
>(({ tabSelected, responsive, menuOpened, onClick, ...props }, ref) => {
	const theme = useTheme() as ThemeType;

	const [opacity, setOpacity] = useState<number>(0);
	const [medias, setMedias] = useState<JSX.Element[]>([]);
	const [amountLoaded, setAmountLoaded] = useState<number>(0);

	const project = getProjects(theme)[tabSelected];

	useEffect(() => setOpacity(1), []);

	useEffect(() => {
		// Quand on clique sur un nouvel onglet et que des images sont déjà chargées,
		// on vide le tableau pour reset le chargement du projet.
		if (medias.length > 0) setMedias([]);
		if (amountLoaded > 0) setAmountLoaded(0);
		// eslint-disable-next-line
	}, [tabSelected]);

	useEffect(() => {
		// On lance le chargement des images du projet.
		if (medias.length === 0) {
			const updatedMedias = project.medias.map((m, i) => {
				const type = m.type ? m.type : 'img';
				const key = `${m.type}-${tabSelected}-${m.index}`;
				const src = require(`../media/${project.url}/${type}/${m.index}.${
					type === 'vid' ? 'mov' : 'png'
				}`);
				const props: MediaType = {
					project: project.name,
					index: m.index,
					size: m.size ? m.size : 'large',
					style: m.style ? m.style : undefined,
					styleDark: m.styleDark ? m.styleDark : undefined,
					handleLoaded: () => {
						setAmountLoaded((amountLoaded) => {
							return amountLoaded + 1;
						});
					},
				};

				if (type === 'img') {
					return <Image key={key} src={src} {...props} />;
				} else {
					return <VideoMobile key={key} src={src} {...props} />;
				}
			});

			setMedias(updatedMedias);
		}
		// eslint-disable-next-line
	}, [medias.length]);

	return (
		<ProjectElement
			ref={ref}
			$pageLoaded={amountLoaded === medias.length}
			$darkMode={theme.darkMode}
			$responsive={responsive}
			$menuOpened={menuOpened}
			onClick={() => onClick()}
			style={{ opacity }}
			{...props}
		>
			<div className='project'>
				{amountLoaded === medias.length ? (
					<ProjectDescription darkMode={theme.darkMode} project={project} />
				) : (
					<ProjectLoader
						darkMode={theme.darkMode}
						amountLoaded={amountLoaded}
						imagesAmount={medias.length}
					/>
				)}
				{medias.map((media) => media)}
			</div>
		</ProjectElement>
	);
});

export default Project;
