import { FC, useEffect, useState } from 'react';
import Icon from './Icon';
import { ReactComponent as SvgMenu } from '../svg/menu.svg';
import { ReactComponent as SvgClose } from '../svg/close.svg';
import { useTheme } from 'styled-components';
import { ResponsiveType } from '../types';

const MenuTrigger: FC<{
	responsive: ResponsiveType;
	opened: boolean;
	scrolled: boolean;
	handleOpened: () => void;
}> = ({ responsive, opened, scrolled, handleOpened }) => {
	const theme = useTheme();

	const [opacity, setOpacity] = useState<number>(0);

	useEffect(() => setOpacity(1), []);

	return (
		<div
			style={{
				position: 'absolute',
				zIndex: 2,
				left: opened
					? theme.menuWidth + theme.margins[responsive]
					: theme.margins[responsive],
				width: 50,
				height: 50,
				borderRadius: scrolled ? 100 : 10,
				border: `1px solid ${
					(theme.darkMode ? '#FFFFFF' : theme.colors.dark) +
					(scrolled ? '00' : '30')
				}`,
				backgroundColor:
					(theme.darkMode ? theme.colors.dark : '#FFFFFF') +
					(scrolled ? '99' : ''), // 99 représente 60%,
				opacity,
				transition: 'all .2s ease, opacity .5s ease',
			}}
			onClick={() => handleOpened()}
		>
			<Icon
				Element={opened ? SvgClose : SvgMenu}
				fill={theme.darkMode ? 'white' : theme.colors.dark}
				size={48}
			/>
		</div>
	);
};

export default MenuTrigger;
