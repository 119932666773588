import { ProjectType } from './types';
import { ThemeType } from './style/ThemeProvider';

const getProjects = (theme: ThemeType): ProjectType[] => [
	{
		name: 'Karbur',
		url: 'karbur',
		description: `Conception et développement d'une boutique en ligne fluide et responsive. Développement React JS (front-end) et Node JS (back-end).
			Intégration du paiement via Stripe et API pour automatiser les commandes et les expéditions.`,
		jobs: [
			'Design UX / UI',
			'Maquette Figma',
			'Front React JS',
			'Back API Node.js',
			'Base de données PostgreSQL / Prisma',
			'Dessins à la main',
			'Illustrations Photoshop',
		],
		medias: [
			{
				index: 5,
				style: { border: `1px solid ${theme.colors.greyLight}` },
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 6,
				style: { border: `1px solid ${theme.colors.greyLight}` },
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 7,
				style: { border: `1px solid ${theme.colors.greyLight}` },
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{ index: 1, size: 'small' },
			{ index: 2, size: 'small' },
			{ index: 3, size: 'small' },
			{ index: 4, size: 'small' },
		],
	},
	{
		name: 'Trainwatch',
		url: 'trainwatch',
		description: `Application iOS de gestions d'alertes sur l'évolution des prix de billets SNCF.
        Recherche de trains sur un trajet, via l'API SNCF.
        Activer des alertes sur les billets, en seconde ou première classe.
        Liste de gestions des alertes créées par l'utilisateur.
        Définir différents états par alertes, active, expirée, offre intéressante, etc.
        Un programme en back permet de vérifier l'état des billets sur les alertes actives des utilisateurs,
        pour leur envoyer des notifications.`,
		jobs: [
			'Design UX / UI',
			'Icons / illustrations SVG',
			'Front React Natif app iOS',
			'Back API Node.js',
			'Base de données PostgreSQL / Prisma',
		],
		medias: [
			{ index: 1, type: 'vid', size: 'small' },
			{ index: 2, type: 'vid', size: 'small' },
			{ index: 1, size: 'small' },
			{ index: 2, size: 'small' },
			{ index: 3, size: 'small' },
			{ index: 4, size: 'small' },
			{ index: 5, size: 'small' },
			{ index: 6, size: 'small' },
		],
	},
	{
		name: 'Warehouse Manager',
		url: 'warehousemanager',
		description: `Jeu de gestion avec une interface tableau de bord.
		Le joueur doit organiser son stock et gérer la circulation des trains entrants / sortants,
		tout en contrôlant régulièrement de nouvelles informations entrantes.
		Il lui est possible de consulter en détail les informations de plusieurs composants,
		sans que cela ne perturbe sa vision globale de l'interface.
		Développement React JS pour le web.`,
		jobs: ['Design UX / UI', 'Illustrations SVG', 'Maquette Figma', 'React JS'],
		defaultMode: 'dark',
		medias: [
			{
				index: 1,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 2,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 3,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 4,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 5,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 6,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 7,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
			{
				index: 8,
				styleDark: { border: `1px solid ${theme.colors.greyDark}` },
			},
		],
	},
	{
		name: 'Quicktalk',
		url: 'quicktalk',
		description: `Maquette et prototype d'application mobile iOS pour la gestion simplifiée d'appels professionnels en équipe pour petites entreprises.
		L'interface présente les appels entre les clients et les membres de l'équipe,
		et permet de voir qui a appelé qui, et quelles informations ont été notées sur chaque échange.`,
		jobs: [
			'Design UX / UI',
			'Maquette Figma',
			'Prototype Figma',
			'Icons SVG',
			'Application mobile',
		],
		medias: [{ index: 1 }, { index: 2 }, { index: 3 }, { index: 4 }],
	},
	{
		name: 'Viruscamp',
		url: 'viruscamp',
		description: `Maquettes et composants graphiques. Agrégateur d'antivirus en application desktop.
		Interface admin pour le poste individuel ou le réseau d'entreprise, gestion des antivirus et des analyses selon les droits des utilisateurs.`,
		jobs: ['Design UX / UI', 'Icons SVG', 'Application desktop'],
		medias: [
			{ index: 1, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 2, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 3, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 4, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 5, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 6, style: { border: `1px solid ${theme.colors.greyLight}` } },
			{ index: 7, style: { border: `1px solid ${theme.colors.greyLight}` } },
		],
	},
];

export default getProjects;
