import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Loader from './Loader';

const Container = styled.div<{ $darkMode: boolean }>(
	({ $darkMode, theme }) => ({
		display: 'flex',
		alignItems: 'center',
		color: $darkMode ? 'withe' : theme.colors.dark,
		width: '100%',

		span: {
			marginLeft: 4,
			color: $darkMode ? theme.colors.grey : theme.colors.grey,
		},
	})
);

const ProjectLoader: FC<{
	darkMode: boolean;
	amountLoaded: number;
	imagesAmount: number;
}> = ({ darkMode, amountLoaded, imagesAmount }) => {
	const theme = useTheme();

	return (
		<Container $darkMode={darkMode}>
			<Loader color={darkMode ? 'white' : theme.colors.dark} />
			<span>
				Chargement {amountLoaded}/{imagesAmount}
			</span>
		</Container>
	);
};

export default ProjectLoader;
