import Icon from './Icon';
import styled, { useTheme } from 'styled-components';
import { ReactComponent as SvgSun } from '../svg/sun.svg';
import { ReactComponent as SvgMoon } from '../svg/moon.svg';
import { FC } from 'react';
import { ThemeType } from '../style/ThemeProvider';

const Container = styled.div(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',

	'&:hover': {
		cursor: 'pointer',
	},

	'.Icon': {
		marginLeft: 6,
	},
}));

const Element = styled.div<{ $activated: boolean }>(
	({ $activated, theme }) => ({
		position: 'relative',
		width: 40,
		height: 24,
		borderRadius: 24,
		backgroundColor: $activated ? 'white' : theme.colors.grey,

		'&:active': {
			boxShadow: `0 0 0 3px ${
				$activated ? theme.colors.grey : theme.colors.greyLight
			}`,
		},

		div: {
			position: 'absolute',
			width: 16,
			height: 16,
			margin: `4px 4px 4px ${$activated ? '20px' : '4px'}`,
			borderRadius: '100%',
			backgroundColor: $activated ? theme.colors.dark : 'white',
			transition: 'margin .2s ease, background-color .1s ease',
		},
	})
);

const Switch: FC<{ activated: boolean; handleClick: () => void }> = ({
	activated,
	handleClick,
}) => {
	const theme = useTheme() as ThemeType;

	return (
		<Container onClick={handleClick} className='Switch'>
			<Element $activated={activated}>
				<div></div>
			</Element>
			<Icon
				Element={activated ? SvgMoon : SvgSun}
				fill={activated ? 'white' : theme.colors.grey}
				size={40}
			/>
		</Container>
	);
};

export default Switch;
