import { FC } from 'react';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as SvgLoader } from '../svg/loader.svg';
import Icon from './Icon';

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
	animation: ${spinAnimation} 1s linear infinite;
`;

const Loader: FC<{ color: string }> = ({ color }) => {
	return (
		<Container>
			<Icon Element={SvgLoader} size={30} fill={color} />
		</Container>
	);
};

export default Loader;
