import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Menu from './components/Menu';
import Project from './components/Project';
import styled, { ThemeProvider } from 'styled-components';
import { Route, useLocation, Switch } from 'react-router-dom';
import { ResponsiveType } from './types';
import getResponsive from './utils/getResponsive';
import MenuTrigger from './components/MenuTrigger';
import ResetStyle from './style/ResetStyle';
import GlobalStyle from './style/GlobalStyle';
import { ThemeContext, ThemeContextType } from './style/ThemeProvider';
import getProjects from './getProjects';

const Container = styled.div(({ theme }) => {
	return {
		display: 'flex',
		marginTop: 40,

		[`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
			marginTop: 20,
		},
	};
});

const App = () => {
	const { theme, setDarkMode } = useContext(ThemeContext) as ThemeContextType;

	const [init, setInit] = useState<boolean>(false);
	const [tabSelected, setTabSelected] = useState<number | null>(null);
	const [menuOpened, setMenuOpened] = useState<boolean>(true);

	const responsive: ResponsiveType = getResponsive(window.innerWidth, theme);
	const location = useLocation();
	const projects = getProjects(theme);
	const urls = projects.map((project) => '/' + project.url);

	const ref = useRef<HTMLDivElement | null>(null);
	const [scrolled, setScrolled] = useState<boolean>(false);

	const handleScroll = useCallback(
		(e: Event) => {
			if (init) {
				if ((e.currentTarget as HTMLDivElement).scrollTop > 40)
					setScrolled(true);
				else setScrolled(false);
			}
		},
		[init]
	);

	useEffect(() => {
		if (ref.current) ref.current.addEventListener('scroll', handleScroll);
	}, [handleScroll]);

	useEffect(() => {
		// On définit le projet à partir de l'url.
		const setProject = (pathname: string) => {
			const url = pathname.substring(1);

			// Si l'url correspond a un projet, on masque le menu.
			if (projects.map((p) => p.url).indexOf(url) === -1) {
				setMenuOpened(true);
			} else {
				setMenuOpened(false);
			}

			projects.forEach((p, i) => {
				if (p.url === url) {
					setTabSelected(i);
					setDarkMode(p.defaultMode === 'dark');
				}
			});
		};

		if (!init) {
			if (location.pathname !== '/') {
				setProject(location.pathname);
			}

			setTimeout(() => setInit(true), 200);
		}
		// eslint-disable-next-line
	}, [init, location.pathname]);

	return (
		<ThemeProvider theme={theme}>
			<ResetStyle />
			<GlobalStyle />
			{init ? (
				<Container>
					{responsive !== 'desktop' && tabSelected !== null ? (
						<MenuTrigger
							responsive={responsive}
							opened={menuOpened}
							scrolled={scrolled}
							handleOpened={() => setMenuOpened(!menuOpened)}
						/>
					) : null}
					<Menu
						tabSelected={tabSelected}
						responsive={responsive}
						opened={menuOpened}
						handleTab={(tab) => {
							setTabSelected(tab);

							if (responsive !== 'desktop') setMenuOpened(false);
						}}
						handleDarkMode={(mode) => {
							setDarkMode(mode);
						}}
					/>
					<Switch>
						<Route path={urls}>
							{tabSelected !== null ? (
								<Project
									ref={ref}
									tabSelected={tabSelected}
									responsive={responsive}
									menuOpened={menuOpened}
									onClick={() => {
										// Sur mobile, si un projet est déjà sélectionné, on ferme le menu en cliquant sur le projet ouvert.
										if (
											responsive !== 'desktop' &&
											menuOpened &&
											tabSelected !== null
										)
											setMenuOpened(false);
									}}
								/>
							) : (
								<></>
							)}
						</Route>
					</Switch>
				</Container>
			) : null}
		</ThemeProvider>
	);
};

export default App;
