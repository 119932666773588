import { FC, ReactNode, createContext, useState } from 'react';

export type ThemeType = {
	darkMode: boolean;
	menuWidth: number;
	breakpoints: {
		tablet: number;
		mobile: number;
	};
	medias: {
		large: number;
		medium: number;
		small: number;
	};
	margins: {
		desktop: number;
		tablet: number;
		mobile: number;
	};
	colors: {
		dark: string;
		greyDark: string;
		grey: string;
		greyLight: string;
	};
};

const initialTheme: ThemeType = {
	darkMode: false,
	menuWidth: 200,
	breakpoints: {
		tablet: 1000,
		mobile: 480,
	},
	medias: {
		large: 1000,
		medium: 800,
		small: 320,
	},
	margins: {
		desktop: 80,
		tablet: 40,
		mobile: 32,
	},
	colors: {
		dark: '#1C1D1D',
		greyDark: '#3F4246',
		grey: '#B3B6BB',
		greyLight: '#E0E1E3',
		// greys: ['red', '#8A91A0', '#B9BDC6', '#E3E5E8'],
	},
};

export type ThemeContextType = {
	theme: ThemeType;
	setDarkMode: (mode: boolean) => void;
};

export const ThemeContext = createContext<ThemeContextType | undefined>(
	undefined
);

export const ThemeProvider: FC<{
	children: ReactNode;
}> = ({ children }) => {
	const [theme, setTheme] = useState<ThemeType>(initialTheme);

	const setDarkMode = (mode: boolean) => {
		setTheme((prevTheme) => ({
			...prevTheme,
			darkMode: mode,
		}));
	};

	return (
		<ThemeContext.Provider value={{ theme, setDarkMode }}>
			{children}
		</ThemeContext.Provider>
	);
};
