import styled, { useTheme } from 'styled-components';
import Video from './Video';
import { FC } from 'react';
import { ThemeType } from '../style/ThemeProvider';
import { VideoType } from '../types';

const Container = styled.div(() => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',

	img: {
		position: 'absolute',
		left: 0,
		right: 0,
		width: '100%',
	},

	video: {
		width: '100%',
		height: '100%',
		padding: '4.2% 5.38%',
		borderRadius: '9%',
	},
}));

const VideoMobile: FC<VideoType> = ({
	handleLoaded,
	style,
	styleDark,
	...props
}) => {
	const theme = useTheme() as ThemeType;

	const containerStyle = theme.darkMode ? styleDark : style;

	const maxWidth = {
		small: theme.medias.small,
		medium: theme.medias.medium,
		large: theme.medias.large,
	}[props.size];

	return (
		<Container className='VideoMobile' style={{ maxWidth, ...containerStyle }}>
			<img
				src={require('../media/src/mobile-mask.png')}
				alt={props.index + '-' + props.project}
			/>
			<Video handleLoaded={handleLoaded} {...props} />
		</Container>
	);
};

export default VideoMobile;
