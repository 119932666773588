import { FC, useState } from 'react';
import { useEffect } from 'react';
import { ImageType } from '../types';
import { useTheme } from 'styled-components';
import { ThemeType } from '../style/ThemeProvider';

const Image: FC<ImageType> = ({
	project,
	index,
	size,
	style,
	styleDark,
	handleLoaded,
	...props
}) => {
	const theme = useTheme() as ThemeType;

	const [loaded, setLoaded] = useState<boolean>(false);

	const containerStyle = theme.darkMode ? styleDark : style;

	const maxWidth = {
		small: theme.medias.small,
		medium: theme.medias.medium,
		large: theme.medias.large,
	}[size];

	useEffect(() => {
		if (loaded) {
			handleLoaded();
		}
	}, [handleLoaded, loaded]);

	return (
		<img
			className='Image'
			onLoad={() => setLoaded(true)}
			alt={`${index}, projet ${project}`}
			style={{ maxWidth, ...containerStyle }}
			{...props}
		/>
	);
};

export default Image;
