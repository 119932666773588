import React, { FC } from 'react';
import styled from 'styled-components';

type IconProps = React.HTMLAttributes<HTMLDivElement> &
	SvgProps & {
		Element: FC<React.SVGProps<SVGSVGElement>>;
	};

export type SvgProps = {
	size?: number;
	fill?: string;
	rotate?: number;
};

const SvgContainer = styled.div<SvgProps>(({ size, fill, rotate, theme }) => ({
	display: 'flex',
	alignItems: 'center',
	width: size ? size : 50,
	height: size ? size : 50,

	svg: {
		transform: rotate ? `rotate(${rotate}deg)` : undefined,

		'gap, path, circle': {
			fill: fill ? fill : theme.colors.dark,
		},
	},
}));

const Icon: FC<IconProps> = ({ Element, size, fill, rotate, ...props }) => {
	return (
		<SvgContainer
			className='Icon'
			size={size}
			fill={fill}
			rotate={rotate}
			{...props}
		>
			{/* Viewbox correspond à la taille de l'export */}
			<Element viewBox={`0 0 50 50`} width='100%' height='100%' />
		</SvgContainer>
	);
};

export default Icon;
