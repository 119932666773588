import { FC, useState } from 'react';
import { VideoType } from '../types';

const Video: FC<VideoType> = ({ handleLoaded, ...props }) => {
	const [init, setInit] = useState<boolean>(false);

	const canPlay = () => {
		if (!init) {
			handleLoaded();
			setInit(true);
		}
		// Sans ça l'élément video trigger handleLoaded à chaque loop,
		// et ajouter un élément à charger supplémentaire, erreur loading 9/8.
	};

	return (
		<video
			autoPlay
			loop
			muted
			controls={false}
			playsInline
			disablePictureInPicture
			onCanPlay={canPlay}
		>
			<source type='video/mp4' {...props} />
			Your browser does not support the video tag.
		</video>
	);
};

export default Video;
