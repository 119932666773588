import { createGlobalStyle } from 'styled-components';

const ResetStyle = createGlobalStyle(({ theme }) => ({
	'*': {
		margin: 0,
		padding: 0,
		boxSizing: 'border-box',
		marginBlockStart: 0,
		marginBlockEnd: 0,
	},
	html: {
		textRendering: 'geometricPrecision',
		MozOsxFontSmoothing: 'antialiased',
		WebkitFontSmoothing: 'antialiased',
	},
	body: {
		letterSpacing: 0,
	},
	a: {
		fontWeight: 'inherit',
		textDecoration: 'inherit',
		color: 'inherit',
		'&:visited': { color: 'inherit' },
	},
}));

export default ResetStyle;
