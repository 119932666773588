import { FC } from 'react';
import styled from 'styled-components';
import { ProjectType } from '../types';

const Container = styled.div<{ $darkMode: boolean }>(
	({ $darkMode, theme }) => ({
		position: 'relative',
		flex: 'none',
		width: '100%',
		maxWidth: theme.breakpoints.tablet,
		marginBottom: 60,

		'p.content': {
			fontSize: 18,
			lineHeight: 1.4,
			color: $darkMode ? 'white' : theme.colors.dark,

			'span.title': {
				// color: darkMode ? theme.colors.greys[0] : theme.colors.greys[2],
				fontWeight: 500,
				fontStyle: 'italic',
				marginRight: 16,
			},
		},

		'p.tags': {
			display: 'flex',
			flexWrap: 'wrap',
			marginTop: 20,
			fontSize: 14,
			fontWeight: 500,
			color: $darkMode ? theme.colors.grey : theme.colors.greyDark,

			'> span': {
				backgroundColor: $darkMode
					? theme.colors.greyDark
					: theme.colors.greyLight,
				padding: '3px 10px',
				marginRight: 4,
				marginBottom: 4,
				borderRadius: 4,
				whiteSpace: 'nowrap',
			},
		},

		[`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
			marginBottom: 40,
		},
	})
);

const ProjectDescription: FC<{
	darkMode: boolean;
	project: ProjectType;
}> = ({ darkMode, project }) => {
	const { url, name, description, jobs } = project;

	return (
		<Container $darkMode={darkMode}>
			<p className='content'>
				<span className='title'>{name}</span>
				{description}
			</p>
			<p className='tags'>
				{jobs.map((job, index) => (
					<span key={`job-${url}-${index}`}>{job}</span>
				))}
			</p>
		</Container>
	);
};

export default ProjectDescription;
