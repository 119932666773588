import React, { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Icon from './Icon';
import { ThemeType } from '../style/ThemeProvider';

const Container = styled.div<{
	$size?: number;
	$hoverFill?: string;
	$disabled?: boolean;
}>(({ $size, $hoverFill, $disabled, theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: 500,
	borderRadius: 8,
	minWidth: $size ? $size : 40,
	minHeight: $size ? $size : 40,
	textAlign: 'center',
	boxSizing: 'border-box',
	backgroundColor: 'transparent',
	userSelect: 'none',
	pointerEvents: $disabled ? 'none' : undefined,
	opacity: $disabled ? 0.4 : undefined,

	'&:hover': {
		cursor: 'pointer',
		backgroundColor: $hoverFill,
	},

	'&:active': {
		backgroundColor: $hoverFill,
		borderColor: theme.colors.dark,
	},
}));

const IconButton: FC<{
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	title?: string;
	fill?: string;
	hoverFill?: string;
	iconSize?: number;
	size?: number;
	fontSize?: number;
	style?: React.CSSProperties;
	disabled?: boolean;
	handleClick: () => void;
}> = ({
	icon,
	title,
	fill,
	hoverFill,
	iconSize,
	size,
	fontSize,
	style,
	disabled,
	handleClick,
}) => {
	const theme = useTheme() as ThemeType;

	return (
		<Container
			style={style}
			$hoverFill={hoverFill}
			$size={size}
			$disabled={disabled}
			onClick={() => (!disabled ? handleClick() : null)}
		>
			<Icon
				size={iconSize ? iconSize : 36}
				fill={fill ? fill : theme.colors.dark}
				Element={icon}
			/>
			{title ? (
				<span
					style={{
						color: fill ? fill : undefined,
						fontSize: fontSize ? fontSize : 'inherit',
						marginLeft: 6,
						paddingRight: 10,
					}}
				>
					{title}
				</span>
			) : null}
		</Container>
	);
};

export default IconButton;
