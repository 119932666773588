import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle(({ theme }) => ({
	html: {
		backgroundColor: theme.darkMode ? theme.colors.dark : 'white',
		transition: 'background-color .2s ease',
	},
	body: {
		color: theme.colors.dark,
		fontFamily: '"Roboto", sans-serif',
	},
}));

export default GlobalStyle;
