import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import Switch from './Switch';
import Icon from './Icon';
import { ReactComponent as Logo } from '../svg/logo.svg';
import { ReactComponent as SvgResume } from '../svg/resume.svg';
import { ReactComponent as SvgDribbble } from '../svg/dribbble.svg';
import { ResponsiveType } from '../types';
import Li from './Li';
import IconButton from './IconButton';
import { ThemeType } from '../style/ThemeProvider';
import getProjects from '../getProjects';

const Container = styled.div<{
	$responsive: ResponsiveType;
	$opened: boolean;
	$darkMode: boolean;
}>(({ $opened, theme }) => {
	return {
		position: 'fixed',
		zIndex: 1,
		width: theme.menuWidth,
		marginLeft: 40,
		transition: 'margin .2s ease',
		lineHeight: 1.4,

		'.Switch': {
			marginTop: 20,
		},

		ul: {
			marginTop: 15,
		},

		[`@media (max-width: ${theme.breakpoints.tablet}px)`]: {
			position: 'fixed',
			marginLeft: $opened ? 20 : -theme.menuWidth,
		},

		[`@media (max-width: ${theme.breakpoints.mobile}px)`]: {
			marginLeft: $opened ? 16 : -theme.menuWidth,
		},
	};
});

const Menu: FC<{
	tabSelected: number | null;
	responsive: ResponsiveType;
	opened: boolean;
	handleTab: (tab: number) => void;
	handleDarkMode: (mode: boolean) => void;
}> = ({ tabSelected, responsive, opened, handleTab, handleDarkMode }) => {
	const theme = useTheme() as ThemeType;

	const darkMode = theme.darkMode;

	return (
		<Container $responsive={responsive} $opened={opened} $darkMode={darkMode}>
			<Icon
				Element={Logo}
				fill={darkMode ? 'white' : theme.colors.dark}
				size={60}
			/>
			<Switch
				activated={darkMode}
				handleClick={() => handleDarkMode(!darkMode)}
			/>
			<ul>
				{getProjects(theme).map((project, index) => (
					<Li
						key={'menu-li-' + index}
						url={project.url}
						darkMode={darkMode}
						selected={tabSelected === index}
						onClick={() => {
							handleTab(index);
							handleDarkMode(project.defaultMode === 'dark');
						}}
					>
						{project.name}
					</Li>
				))}
			</ul>
			<div
				style={{
					marginTop: responsive === 'desktop' ? 30 : 20,
					display: 'flex',
				}}
			>
				<IconButton
					icon={SvgResume}
					style={{ marginLeft: -8 }}
					fill={darkMode ? 'white' : theme.colors.dark}
					hoverFill={darkMode ? '#FFFFFF' + 20 : theme.colors.dark + 20}
					handleClick={() =>
						window.open(require(`../doc/resume.pdf`), '_blank')
					}
				/>
				<IconButton
					icon={SvgDribbble}
					fill={darkMode ? 'white' : theme.colors.dark}
					hoverFill={darkMode ? '#FFFFFF' + 20 : theme.colors.dark + 20}
					handleClick={() =>
						window.open('https://dribbble.com/arthurcd', '_blank')
					}
				/>
			</div>
		</Container>
	);
};

export default Menu;
