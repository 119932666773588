import { ResponsiveType } from '../types';
import { ThemeType } from '../style/ThemeProvider';

const getResponsive = (width: number, theme: ThemeType): ResponsiveType => {
	if (width <= theme.breakpoints.mobile) return 'mobile';
	else if (width <= theme.breakpoints.tablet) return 'tablet';
	else return 'desktop';
};

export default getResponsive;
